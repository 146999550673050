import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '../../../assets/images/DestinationCardIcon.png';
import * as APIController from "../../API/APIController";
import { Button, CommonLoader, CommonModal, Text } from '../../components';
import DestinationTab from '../../components/DestinationTab/DestinationTab';
import { Images } from '../../config';
import { getcurrentcountry, IsEmpty } from '../../config/appConstant';
import Packages from '../../screens/Packages/Packages';
import './DestinationCountry.css';
import { useDispatch, useSelector } from 'react-redux';
import { setBundle } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import SignIn from '../../screens/SignIn/SignIn';
import SignUp from '../../screens/SignUp/SignUp';


const Destinations = (props) => {
  const { searchText } = props
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [countrylist, setCountryList] = useState([]);
  const [Regionlist, setRegionlist] = useState([]);
  const [Regionholder, setRegionholder] = useState([]);
  const [arrayholder, setArrayHolder] = useState([]);
  const [filtercountry, setFilterCountry] = useState([]);
  const [selectedTab, setselectedTab] = useState(0);
  const [selectBundle, setselectBundle] = useState([]);
  const [selectCountryRegion, setselectCountryRegion] = useState([]);
  const { t } = useTranslation();
  const [openbundleModal, setOpenbundleModal] = useState(false);


  const IsLogin = useSelector((state) => state.il);
  const [showSignIn, setShowSignIn] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let locations = await getcurrentcountry();
        wsGetESIMCountries(locations.Country);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    setselectBundle([])
    fetchData();
  }, []);



  useEffect(() => {
    filteredDestinations(searchText)
  }, [searchText]);


  const wsGetESIMCountries = () => {
    APIController.GetESIMCountries()
      .then((response) => {
        if (IsEmpty(response.error)) {
          setLoading(false);
          if (response.object.Status == 1) {
            if (!IsEmpty(response.object.CountryList)) {
              setCountryList(response.object.CountryList);
              setArrayHolder(response.object.CountryList);
              handleTab(0, response.object.CountryList)
              const uniqueRegionsArray = getUniqueRegions(response.object.CountryList);
              setRegionlist(uniqueRegionsArray)
              setRegionholder(uniqueRegionsArray)
            }
          }
        }
      }).catch((err) => {
        console.log('err:', err);
      });
  };



  const BundleOpen = (val) => {
    let object = {
      ...val,
      selectedTab: selectedTab
    }
    setselectCountryRegion(object)
    setOpenbundleModal(true)
  }

  function getUniqueRegions(countries) {
    const regions = countries.map(country => {
      return {
        'CountryName': country.Region || 'Unknown',
        'CountryImage': country.RegionImage || Images.Earth_IC
      };
    });
    const uniqueRegions = [...new Set(regions.map(region => JSON.stringify(region)))].map(str => JSON.parse(str));
    return uniqueRegions;
  }

  function filteredDestinations(searchText) {
    if (IsEmpty(searchText)) {
      setFilterCountry(selectedTab == 0 || selectedTab == -1 ? arrayholder : Regionholder);
    } else {
      const lowercasedSearchText = String(searchText).toLowerCase();
      if (selectedTab == 0 || selectedTab == -1) {
        setFilterCountry(countrylist.filter(destination => destination.CountryName.toLowerCase().includes(lowercasedSearchText)));
      } else {
        setFilterCountry(Regionlist.filter(destination => destination.CountryName.toLowerCase().includes(lowercasedSearchText)));
      }
    }
  }

  const handleTab = (val, list) => {
    setFilterCountry(!IsEmpty(list) ? list : val == 0 ? countrylist : Regionlist)
    setselectedTab(val)
  }

  const handeBundleSelect = (val) => {
    dispatch(setBundle(val));
    setselectBundle(val)
  }

  const switchToSignUp = () => {
    setShowSignIn(false); // Switch to SignUp screen
  };

  const handleclosebundlepopup = () => {
    setOpenbundleModal(false)
    setShowSignIn(true);
  }

  return (
    <section className="destinations">
      <div className='destination-header-main'>
        <div className='destination-headertext'><Text lineheightsmall whiteColor bold heading >{t("Destination_Card_title")}</Text></div>
        <div><DestinationTab defaultselectedTab={selectedTab} handleTab={handleTab.bind(this)} tabs={[t('Country'), t('Zone')]} /></div>
      </div>

      <div className="destination-cards" >
        {filtercountry?.map((dest, index) => {
          return (
            <>{
              index < 10 &&
              <div key={index} className="destination-card" onClick={() => BundleOpen(dest)} >
                <div className='destination-flag-cnt'>
                  <div className="flag">
                    <img src={dest.CountryImage} width={60} style={{ minWidth: '60px' }} />
                  </div>
                  <div>
                    <div className="country"><Text lineheightsmall mainColor bold title5 >{dest.CountryName}</Text></div>
                    <div className="country"><Text lineheightsmall blackColor semibold body1 >{dest.price}</Text></div>
                  </div>
                </div>
                <div className='destination-arrow' > <img src={ArrowIcon} style={{ width: '18px' }} /></div>
              </div>
            }
            </>
          )
        })}
      </div>
      <div className='view-all-btn'>
        <Button Large round variant='contained' onClick={() => navigate("/destination", { state: selectedTab })} title={t('View_all_destinations')} />
      </div>

      {IsLogin ?
        <CommonModal IsScroll={true} maxWidth={'xl'} onClose={handleclosebundlepopup} visible={openbundleModal} >
          <Packages selectCountryRegion={selectCountryRegion} handeBundleSelect={handeBundleSelect.bind(this)} selectBundle={selectBundle} />
        </CommonModal>
        :
        <CommonModal IsScroll={true} maxWidth={'xl'} onClose={handleclosebundlepopup} visible={openbundleModal} >
          {showSignIn ? (<SignIn onSignUpClick={switchToSignUp}  />) : (<SignUp />)}
        </CommonModal>}

    </section>
  );
}
export default Destinations;
