import React from 'react';
import './Installationstep.css';
import { Accordion, Text } from '../../components';
import Phone from '../../../assets/images/Device 13PM.png';
import Group from '../../../assets/images/Group.png';
import { useTranslation } from 'react-i18next';

const steps = [
    { number: "01", title: "Intallation_stepone", description: "Intallation_stepone_description", description1: "Intallation_description" },
    { number: "02", title: "Intallation_steptwo", description: "Intallation_steptwo_description", description1: "Intallation_description" },
    { number: "03", title: "Intallation_stepthree", description: "Intallation_stepthree_description", description1: "Intallation_description" },

];
const Installtionstep = () => {
    const { t } = useTranslation();



    return (
        <div className='Installtionstep-main'>
            <div className="Installtionstep-textheader">
                <Text bold mainColor heading >Getting Started</Text>
            </div>
            <div className="Installtionstep-header">
                <div className="Installtionstep-phone-image">
                    <img src={Phone} alt="Phone" />
                </div>
                <div className="Installtionstep-border"></div>
                <div className="Installtionstep-steps">
                    {steps.map((step, index) => (
                        <React.Fragment key={index}>
                            <div className="Installtionstep-circle" style={{ top: `${index * 214}px` }}></div>
                            <div className="Installtionstep-step">
                                <div className="Installtionstep-step-content">
                                    <div className="number"><Text heavy DarkGrayTextColor lineheightsmall header >{step.number}</Text></div>
                                    <div className="text"><Text bold lineheightsmall mainColor title4 >{t(step.title)}</Text></div>
                                    <div className='para'><Text bold lineheightmedium body1 >{t(step.description)}</Text></div>
                                    <div className='para' style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                        <div><img width={15} src={Group} alt="icon" /></div>
                                        <div><Text lineheightmedium bold subhead >{t(step.description1)}</Text></div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="Installtionstep-textheader">
                <Text bold heading mainColor >"Lorem ipsum dolor sit amet</Text> <br />
                <Text lineheightsmall mainColor medium title1 >consectetur adipiscing elit</Text>
            </div>
            <div className='Installtionstep-Accordion'>
                <Accordion title="Apple">
                    <p>This is the content for Apple.</p>
                </Accordion>
                <Accordion title="Android">
                    <p>This is the content for Android.</p>
                </Accordion>
                <Accordion title="Others">
                    <p>This is the content for Others.</p>
                </Accordion>
            </div>

        </div>
    );
}

export default Installtionstep;