import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { RiMenu3Fill } from 'react-icons/ri';
import { CiShoppingCart } from "react-icons/ci";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AlertMessage, Text } from '../../components';
import { BaseColor, Images } from '../../config';
import './navbar.css';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from './languageDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeFirstLetter, excludedScreensFromLogin } from '../../config/appConstant';
import UserActionsDropdown from './UserActionDrodown';
import { setLoginModal } from '../../redux/store';

const NavbarData = [
  { 'Name': 'Home', 'ID': 0, 'link': '/home' },
  { 'Name': 'Destinations', 'ID': 0, 'link': '/destination' },
  { 'Name': 'MyeSIM', 'ID': 0, 'link': '/myeSIM' },
  { 'Name': 'Get_Help', 'ID': 0, 'link': '/gethelp' },
  { 'Name': 'About_Us', 'ID': 0, 'link': '/aboutus' },
  { 'Name': 'Contact_Us', 'ID': 0, 'link': '/contactus' },
]


const Navbar = ({ IsLogin, props }) => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState('');
  const [NavShow, setNavShow] = useState(false);
  const dispatch = useDispatch()
  const [NavbarAlert, setNavbarAlert] = useState(false);
  const Loginmodalopen = useSelector((state) => state.ol);


  useEffect(() => {
    const splitlink = pathname.split("/");
    const activepath = splitlink[1];
    setActiveLink("/" + activepath)
    let ScreenName = capitalizeFirstLetter(window.location.pathname.split('/')[1])
    document.title = `eSIMLife | ${ScreenName}`;

  });

  useEffect(() => {
    if (!IsLogin) {
      dispatch(setLoginModal(excludedScreensFromLogin()))
    }
    else {
      dispatch(setLoginModal(false))
    }
    setNavbarAlert(false)
  }, [pathname])

  return (
    <nav id='nav' className={NavShow ? 'nav-show' : ''} >
      {Loginmodalopen && <AlertMessage props={props} />}
      <div className="nav-main">
        <div className="nav-logo">
          <Link to="/home" aria-label='home'><img src={Images.mainlogo} alt='Technolee' className='logo' /></Link>
        </div>
        <input type="radio" name="slider" id="menu-btn" readOnly />
        <input type="radio" name="slider" id="close-btn" checked readOnly />
        <ul className="nav-links">
          <label htmlFor="close-btn" className="btn close-btn"><AiOutlineClose /></label>
          {NavbarData.map((item, key) => {
            return (
              <li key={key}>
                {item.link == '' ? <Text bold>{t(item.Name)}</Text> :
                  <Link to={Loginmodalopen ? '' : item.link} className={activeLink == item.link ? 'active-link' : ''} >{<Text primaryColor={activeLink == item.link ? true : false}>{t(item.Name)}</Text>}</Link>
                }
              </li>
            )
          })}
          <div className='cart-devider-div'>
            <div className='cart-devider'></div>
          </div>
          <div className='language-dropdown'>
            <LanguageDropdown />
          </div>
          {IsLogin && <div className='language-dropdown'>
            <UserActionsDropdown />
          </div>}

        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn"><RiMenu3Fill color={BaseColor.text} /></label>
      </div>
    </nav >
  );
};

export default Navbar;