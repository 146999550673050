import React, { useState } from 'react';
import './AlertMessage.css'; // Include the CSS for styling
import { CommonLoader, CommonModal, Text } from '../../components';
import { useTranslation } from 'react-i18next';
import { SignIn } from '../../config/PagePathList';
import { useDispatch } from 'react-redux';
import { setLoginModal } from '../../redux/store';

const AlertMessage = ({ message = 'Login_Required', onClose, onContinue }) => {
  const { t } = useTranslation();
  const [NavbarAlert, setNavbarAlert] = useState(false);
  const dispatch = useDispatch()


  const handleCountinue = () => {
    setNavbarAlert(true)
    // onClose(false)
  }
  const handleClose = () => {
    setNavbarAlert(false)
    dispatch(setLoginModal(false))
  }
  return (
    <>
      <div className="alert-overlay">
        <div className="alert-container fade-in">
          <div className="close-icon" onClick={() => handleClose()}>
            &times; {/* You can replace this with an SVG or another icon if desired */}
          </div>
          <Text body1 bold>{t(message)}</Text>
          <div className="alert-buttons">
            <button className="continue-btn" onClick={handleCountinue}>
              <Text whiteColor>
                {t("Continue")}
              </Text>
            </button>
          </div>
        </div>
      </div>
      <CommonModal maxWidth={'xl'} visible={NavbarAlert} onClose={() => handleClose()}>
        <SignIn onLoginSuccess={() => handleClose(false)} />
      </CommonModal>
    </>
  );
};

export default AlertMessage;
